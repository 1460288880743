'use strict';

angular.module('cpformplastApp')
.controller('BreadcrumbController', function ($scope, $state) {
	$scope.crumbs = [];

	let init = function() {
		$scope.crumbs = getStateHierarchy($state.current.name);
	};

	$scope.gotToState = function(state) {
		$state.go(state);
	};

	init();
});
